import React from 'react';
import ReactDOM from 'react-dom';

// boostrap do widoku
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.js';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

// global css
import './application/assets/css/global.css';

// ustawienie reducera i główngo route aplikacji

import { checkIsAuth } from './application/actions/app.action';
import rootReducer from './application/reducers/reducers';
import thunkMiddleware from 'redux-thunk';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';

// główy route aplikacji, routing
import App from './application/containers/app.container';
import Api from './application/services/api.service';

// Note: this API requires redux@>=3.1.0
const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(thunkMiddleware)
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);

// sprawdzamy czy jest zalogowany user
store.dispatch(checkIsAuth());

// ustawimy w api dispatch by można zrobić logout i obsługiwać store
Api.setDispatch(store.dispatch);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
