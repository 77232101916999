import {
    SET_NOTIFICATIONS,
    SET_NOTIFICATION,
    CLEAR_NOTIFICATIONS,
    CLEAR_NOTIFICATION,
    IS_LOADING,
    SET_PAGE,
    SET_OFFSET,
    CLEAR_PAGE
} from '../actions/notifications.action';

const initialState = {
    isLoading: false,
    notifications: [],
    notification: {},
    queryFields: 'text,activeUntil,createdAt,active',
    total: 0,
    page: 1,
    limit: 20,
    offset: 0,
    sortOptions: [
        { value: { 'sort[activeUntil]': -1 }, label: 'Aktywny do malejąco' , default: true},
        { value: { 'sort[activeUntil]': 1 }, label: 'Aktywny do rosnąco' },
        { value: { 'sort[createdAt]': -1 }, label: 'Data dodania malejąco' },
        { value: { 'sort[createdAt]': 1 }, label: 'Data dodania rosnąco' },
        // { value: {'sort[active]' :-1 }, label: 'aktywne' }
    ]
};

export default function NotificationReducer(state = initialState, action) {


    switch (action.type) {
        case SET_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.notifications,
                total: action.total
            };
        case SET_NOTIFICATION:
            return {
                ...state,
                notification: action.notification,
            };
        case CLEAR_NOTIFICATIONS:
            return {
                ...state,
                notifications: []
            };
        case CLEAR_NOTIFICATION:
            return {
                ...state,
                notification: {}
            };
        case SET_PAGE:
            return {
                ...state,
                page: action.page
            };
        case SET_OFFSET:
            return {
                ...state,
                offset: action.offset
            };
        case CLEAR_PAGE:
            return { ...state, page: 1 };

        case IS_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            };
        default:
            return state;
    }
} 