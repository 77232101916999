import React from 'react';
import moment from 'moment';
import { Link } from "react-router-dom";
import '../assets/css/users.css';

moment.locale("pl");

const typeLabels = {
  "freelancer": "Freelancer",
  "company": "Firma",
  "admin": "Administrator",
  "callcenter": "Callcenter"
}

function UserListCardComponent({ index, record }) {
  return <div className={`d-flex list-card-wrapper ${!record.active ? "no-active" : ""} flex-row`}>
    <Link className="users-list d-flex flex-fill" to={`/users/${record._id}`}>
      <div className="card-section card-label-wrapper justify-content-center w-25 col-sm-8 col-md-4 align-items-start d-flex flex-fill flex-column">
        <div className="card-label">Imie i Nazwisko</div>
        <div className="card-label-value">{record.name}</div>
      </div>
      <div className="card-section card-label-wrapper justify-content-center w-25 col-sm-4 align-items-start d-flex flex-fill flex-column">
        <div className="card-label">Typ</div>
        <div className="card-label-value">{typeLabels[record.type]}</div>
      </div>
      <div className="card-section card-label-wrapper  justify-content-center w-25 align-items-start d-flex flex-fill flex-column">
        <div className="card-label">Data utworzenia</div>
        <div className="card-label-value">{moment(record.createdAt).format('DD.MM.YYYY HH:mm')}</div>
      </div>
      <div className="card-section card-label-wrapper  justify-content-center w-25 align-items-start d-flex flex-fill flex-column truncate">
        <div className="card-label">E-mail</div>
        <div className="card-label-value">{record.email}</div>
      </div>
      <div className="card-section card-label-wrapper  justify-content-center align-items-start d-flex flex-fill flex-column">
        <div className="card-label">Status</div>
        <div className="card-label-value">{record.active ? "Aktywny" : "Nieaktywny"}</div>
      </div>
    </Link>
  </div>
}

export default UserListCardComponent;