import React from 'react';
import { Link } from "react-router-dom";
import moment from 'moment';

moment.locale("pl");

function NotificationListCardComponent({ index, record }) {

  return <div className={`d-flex list-card-wrapper ${!record.active ? "no-active" : ""} flex-row`}>
    <Link className="d-flex flex-fill" to={`/notifications/${record._id}`}> 
      <div className="card-section card-label-wrapper  justify-content-center w-25 align-items-start d-flex flex-fill flex-column">
        <div className="card-label">Data dodania</div>
        <div className="card-label-value">{moment(record.createdAt).format("DD.MM.YYYY HH:mm")}</div>
      </div>
      <div className="card-section card-label-wrapper justify-content-center w-25 align-items-start d-flex flex-fill flex-column">
        <div className="card-label">Opis</div>
        <div className="card-label-value">{record.text}</div>
      </div>
     
      <div className="card-section card-label-wrapper  justify-content-center w-25 align-items-start d-flex flex-fill flex-column">
        <div className="card-label">Aktywne do</div>
        <div className="card-label-value">{moment(record.activeUntil).format("DD.MM.YYYY HH:mm")}</div>
      </div>

    </Link>
  </div>
}
export default NotificationListCardComponent;
