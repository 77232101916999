import React from 'react';
import { connect } from 'react-redux';
import NotificationComponent from '../components/notification.component';

import { getNotification, clearNotification, saveNotification, getUsers } from '../actions/notifications.action';

function NotificationContainer(props) {
    return (
        <NotificationComponent {...props} />
    );
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.NotificationReducer.isLoading,
        notification: state.NotificationReducer.notification,
        loggedUser: state.AppReducer.loggedUser
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getNotification: (id) => dispatch(getNotification(id)),
        clearNotification: () => dispatch(clearNotification()),
        saveNotification: (data) => saveNotification(data, dispatch),
        getUsers: (query) => getUsers(query, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationContainer);
