import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import DateTimePicker from 'react-datetime-picker';
import { useHistory, useParams } from "react-router-dom";
import * as Yup from 'yup';
import AppLoader from '../../application/components/loader.component';
import '../../notifications/assets/css/notification.css';

function NotificationComponent(props) {

    let { id } = useParams();
    let history = useHistory();

    const { getNotification, clearNotification, notification, isLoading, saveNotification } = props;
    const [errors, setApiErrors] = useState(null);
    const formData = useCallback(setFormData, [notification]);

    useEffect(() => {
        if (id) {
            getNotification(id);
        }
        return () => {
            clearNotification();
        }
    }, [id, getNotification, clearNotification]);

    useEffect(() => { // jak przyjdzie notifikacja w propsach to ustawiamy go na formularzu
        formData();
    }, [notification, formData]);

    function setFormData() {
        Object.keys(notification).map((name) => {
            formik.setFieldValue(name, notification[name]);
            return true;
        });
    }

    const formik = useFormik({
        initialValues: {
            active: false,
            text: '',
            activeUntil: new Date()

        },
        validationSchema: Yup.object({
            active: Yup.boolean(),
            text: Yup.string().required("Treść powiadomienia jest wymagana"),
            activeUntil: Yup.date().required("Data jest wymagana")
        }),
        onSubmit: (values) => {
            setApiErrors(null);
            saveNotification(values).then(() => {
                history.push('/notifications');
            }, (errors) => {
                setApiErrors(errors);
                formik.setSubmitting(false);
            });
        }
    });
    
    return <div className="wash-wrapper flex-column d-flex flex-fill">

        {isLoading && <AppLoader type="absolute" />}
        <form onSubmit={formik.handleSubmit}>
            <div className="save-wrapper d-flex justify-content-between align-items-center">
                <h4>{id ? "Edycja" : "Dodawanie"} Powiadomień</h4>
                <div className="save-button ">
                    <button type="submit" className="btn btn-primary">ZAPISZ</button>
                </div>
            </div>
            <div className="d-flex flex-fill">
                <div className="flex-fill">
                    <div className="row">
                        <div className="form-group col-md-12 col-lg-6">
                            <label htmlFor="title">Powiadomienie</label>
                            <input
                                autoComplete="text"
                                type="text"
                                className={`form-control ${(formik.errors && formik.touched.text && formik.errors.text) || (errors && errors.text) ? 'is-error' : ''}`}
                                onChange={formik.handleChange}
                                value={formik.values.text}
                                name="text"
                                id="text"
                            />
                            {formik.errors.text && formik.touched.text ? (
                                <div className="error-warpper">{formik.errors.text}</div>
                            ) : null}
                            {errors && errors.text ? (
                                <div className="error-warpper">{errors.text}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-12 col-lg-6">
                            <div className="form-group custom-control custom-checkbox mt-1 mb-0">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    onChange={formik.handleChange}
                                    value={formik.values.active}
                                    checked={formik.values.active}
                                    name="active"
                                    id="active"
                                />
                                {formik.errors.active && formik.touched.active ? (
                                    <div className="error-warpper">{formik.errors.active}</div>) : null}
                                {errors && errors.active ? (
                                    <div className="error-warpper">{errors.active}</div>) : null}
                                <label className="custom-control-label" htmlFor='active'>Aktywny</label>
                            </div>
                        </div>
                    </div>
                   
                    <div className="row">
                            <label htmlFor="title" className="date-time-picker__label">Aktywny do:</label>
                          
                            <DateTimePicker
                                name="activeUntil"
                                id="activeUntil"
                                value={new Date(formik.values.activeUntil)}
                                onChange={(currentDate) => { formik.setFieldValue('activeUntil', currentDate) }}
                                format={"dd-MM-yyyy HH:mm"}
                            />
                      

                    </div>



                </div>
            </div>
        </form>
    </div >
}

export default NotificationComponent;