import React from 'react';
import { connect } from 'react-redux'
import WashesComponent from '../components/washes.component';

import { getWashes, clearWashes, setPaginationRoute, clearPaginationRoute, setFilterBy } from '../actions/washes.action';
import { clearQuery } from '../../application/actions/app.action';

function WashesContainer(props) {
    return (
        <WashesComponent {...props} />
    );
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.WashReducer.isLoading,
        washes: state.WashReducer.washes,
        total: state.WashReducer.total,
        limit: state.WashReducer.limit,
        paginationRoute: state.WashReducer.page,
        sortOptions: state.WashReducer.sortOptions,
        filterOptions: state.WashReducer.filterOptions
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getWashes: () => dispatch(getWashes()),
        clearWashes: () => dispatch(clearWashes()),
        setPaginationRoute: (page) => dispatch(setPaginationRoute(page)),
        clearPaginationRoute: () => dispatch(clearPaginationRoute()),
        clearQuery: () => dispatch(clearQuery()),
        setFilterBy: (filterIndex, isChecked) => dispatch(setFilterBy(filterIndex, isChecked)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(WashesContainer);
