import { useFormik } from 'formik';
import _ from "lodash";
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from "react-router-dom";
import * as Yup from 'yup';
import Autocomplete from "../../application/components/autcomplete.component";
import AppLoader from '../../application/components/loader.component';
import '../assets/css/user.css';

function WashComponent(props) {

    let { id } = useParams();
    let history = useHistory();
    const [address, setAddress] = useState([]);
    const [washes, setWashes] = useState([]);

    const { getUser, clearUser, user, isLoading, saveUser, getPlaces, getPlaceDetails, searchNipData, getWashes } = props;

    useEffect(() => {
        if (id) {
            getUser(id);
        }
        return () => {
            clearUser();
        }
    }, [id, getUser, clearUser]);

    const formData = useCallback(setFormData, [user]);
    useEffect(() => { // jak przyjdzie notifikacja w propsach to ustawiamy go na formularzu
        formData();
    }, [user, formData]);

    function setFormData() {
        Object.keys(user).map((name) => {
            if (name === "washes" && user[name] instanceof Array) {
                let washestoSet = user[name].map((wash) => { return { name: `${wash.name} (${wash.formattedAddress || 'brak adresu'})`, value: wash._id } });
                formik.setFieldValue(name, washestoSet);
                setWashes(washestoSet);
            } else if (name !== 'password') {
                formik.setFieldValue(name, user[name]);
            }
            return true;
        });
    }

    const [errors, setApiErrors] = useState(null);
    const formik = useFormik({
        initialValues: {
            name: '',
            type: 'company',
            active: true,
            NIP: '',
            email: '',
            phone: '',
            password: '',
            agreements: {
                regulationsAcceptance: false,
                privacyPolicyAcceptance: false
            },
            address: {}
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Wartość jest wymagana"),
            type: Yup.string().oneOf(["admin", "callcenter", "company", "freelancer"], "Nieprawidłowy typ").required("Wartość jest wymagana"),
            active: Yup.bool().required("Wartość jest wymagana"),
            NIP: Yup.string(),
            email: Yup.string().required("Wartość jest wymagana"),
            phone: Yup.string().required("Wartość jest wymagana"),
            password: Yup.string(),
            agreements: Yup.object().shape({
                regulationsAcceptance: Yup.boolean().required("Wartość jest wymagana"),
                privacyPolicyAcceptance: Yup.boolean().required("Wartość jest wymagana")
            }),
            address: Yup.object().shape({
                postCode: Yup.string(),
                country: Yup.string(),
                locality: Yup.string(),
                street: Yup.string(),
                streetNumber: Yup.string(),
                area_1: Yup.string(),
                area_2: Yup.string(),
                area_3: Yup.string()
            }),
            washes: Yup.array()
        }),
        onSubmit: (values) => {
            setApiErrors(null);
            values._id = id;
            if (values.washes instanceof Array) {
                values.washes = values.washes.map((wash) => wash.value);
            }
            saveUser(values).then(() => {
                history.push('/users');
            }, (errors) => {
                setApiErrors(errors);
                formik.setSubmitting(false);
            });
        }
    });

    function handleFocusInputs() {
        let materialInput = document.querySelectorAll('.material-input');

        if (materialInput) {
            materialInput.forEach(function (e) {
                let input = e.querySelector('.material-input__input');
                let label = e.querySelector('label');

                if (input && label) {
                    input.addEventListener('focusin', (event) => {
                        label.classList.add('float-label');
                    })

                    input.addEventListener('focusout', (event) => {
                        if (input.value === "") {
                            label.classList.remove('float-label');
                        }
                    })
                }

            })
        }
    }

    function handleAddressSelect(selected) {
        if (selected instanceof Array && selected[0]) {
            getPlaceDetails(selected[0].value).then((data) => {
                formik.setFieldValue("address", _.omit(data, "coordinates"));
                formik.setFieldValue("coordinates", data.coordinates);
            });
        }
    }

    function handleWashSelect(selected) {
        if (selected instanceof Array && selected[0]) {
            formik.setFieldValue("washes", selected);
        }
    }

    function handleSearchNIPData() {
        if (!_.isEmpty(formik.values.NIP)) {
            setApiErrors({});
            searchNipData(formik.values.NIP).then((result) => {
                Object.keys(result).map((name) => {
                    formik.setFieldValue(name, result[name]);
                    return true;
                });
            }, () => {
                setApiErrors({ NIP: "Nie znaleziono klienta" });
                formik.setSubmitting(false);
            })
        }
    }

    function deleteWashSelection(wash) {
        let newValues = _.without(formik.values.washes, wash);
        formik.setFieldValue("washes", newValues);
        setWashes(newValues);
    }

    function renderSelectedWashes() {
        if (!_.isEmpty(formik.values.washes)) {
            return formik.values.washes.map((wash, index) => {
                return <p key={`wash${index}`}>{wash.name} <button type="button" className="btn btn-sm btn-dark" onClick={() => deleteWashSelection(wash)}>Usuń</button><br /></p>
            });
        }
        return null;
    }

    return <div className="wash-wrapper flex-column d-flex flex-fill">
        {isLoading && <AppLoader type="absolute" />}
        <form onSubmit={formik.handleSubmit}>
            <div className="save-wrapper d-flex justify-content-between align-items-center">
                <h4>{id ? "Edycja" : "Dodawanie"} użytkownika</h4>
                <div className="save-button ">
                    <button type="submit" className="btn btn-primary mr-2">ZAPISZ</button>
                    <Link className="btn btn-dark" to="/users">ANULUJ</Link>
                </div>
            </div>
            <div className="d-flex flex-fill">
                <div className="flex-fill">
                    <div className="row">
                        <div className="form-group  col-6 text-center mb-3">
                            <label htmlFor="login">Typ</label>
                            <select
                                autoComplete="type"
                                className={`form-control ${(formik.errors && formik.touched.type && formik.errors.type) || (errors && errors.type) ? 'is-error' : ''}`}
                                onChange={formik.handleChange}
                                value={formik.values.type}
                                name="type"
                                id="type"
                            >
                                <option value="0">Wybierz</option>
                                <option value="admin">Administrator</option>
                                <option value="company">Klient</option>
                                <option value="freelancer">Freelancer</option>
                                <option value="callcenter">Callcenter</option>
                            </select>
                        </div>
                        <div className="col-12 text-center">
                            {formik.errors.type && formik.touched.type ? (
                                <div className="error-warpper">{formik.errors.type}</div>
                            ) : null}
                            {errors && errors.type ? (
                                <div className="error-warpper">{errors.type}</div>
                            ) : null}
                        </div>
                    </div>
                    {formik.values.type === "company" && <div className="row">
                        <div className="form-group col-md-11 col-lg-5">
                            <label htmlFor="title">NIP</label>
                            <input
                                autoComplete="NIP"
                                type="text"
                                className={`form-control ${(formik.errors && formik.touched.NIP && formik.errors.NIP) || (errors && errors.NIP) ? 'is-error' : ''}`}
                                onChange={formik.handleChange}
                                value={formik.values.NIP}
                                name="NIP"
                                id="NIP"
                            />
                            {formik.errors.NIP && formik.touched.NIP ? (
                                <div className="error-warpper">{formik.errors.NIP}</div>
                            ) : null}
                            {errors && errors.NIP ? (
                                <div className="error-warpper">{errors.NIP}</div>
                            ) : null}
                        </div>
                        <div className="col-1">
                            <button type="button" className="btn btn-primary mt-4" onClick={() => handleSearchNIPData()}>ZNAJDŹ</button>
                        </div>
                    </div>}
                    <div className="row">
                        <div className="form-group col-md-12 col-lg-6">
                            <label htmlFor="title">Nazwa użytkownika</label>
                            <input
                                autoComplete="name"
                                type="text"
                                className={`form-control ${(formik.errors && formik.touched.name && formik.errors.name) || (errors && errors.name) ? 'is-error' : ''}`}
                                onChange={formik.handleChange}
                                value={formik.values.name}
                                name="name"
                                id="name"
                            />
                            {formik.errors.name && formik.touched.name ? (
                                <div className="error-warpper">{formik.errors.name}</div>
                            ) : null}
                            {errors && errors.name ? (
                                <div className="error-warpper">{errors.name}</div>
                            ) : null}
                        </div>
                    </div>
                    {!id && <div className="row">
                        <div className="form-group col-md-12 col-lg-6">
                            <label htmlFor="title">Hasło</label>
                            <input
                                autoComplete="password"
                                type="password"
                                className={`form-control ${(formik.errors && formik.touched.password && formik.errors.password) || (errors && errors.password) ? 'is-error' : ''}`}
                                onChange={formik.handleChange}
                                value={formik.values.password}
                                name="password"
                                id="password"
                            />
                            {formik.errors.password && formik.touched.password ? (
                                <div className="error-warpper">{formik.errors.password}</div>
                            ) : null}
                            {errors && errors.password ? (
                                <div className="error-warpper">{errors.password}</div>
                            ) : null}
                        </div>
                    </div>}
                    <div className="row">
                        <div className="form-group col-md-12 col-lg-6">
                            <label htmlFor="title">Email</label>
                            <input
                                autoComplete="email"
                                type="text"
                                className={`form-control ${(formik.errors && formik.touched.email && formik.errors.email) || (errors && errors.email) ? 'is-error' : ''}`}
                                onChange={formik.handleChange}
                                value={formik.values.email}
                                name="email"
                                id="email"
                            />
                            {formik.errors.email && formik.touched.email ? (
                                <div className="error-warpper">{formik.errors.email}</div>
                            ) : null}
                            {errors && errors.email ? (
                                <div className="error-warpper">{errors.email}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-12 col-lg-6">
                            <label htmlFor="title">Telefon</label>
                            <input
                                autoComplete="phone"
                                type="text"
                                className={`form-control ${(formik.errors && formik.touched.phone && formik.errors.phone) || (errors && errors.phone) ? 'is-error' : ''}`}
                                onChange={formik.handleChange}
                                value={formik.values.phone}
                                name="phone"
                                id="phone"
                            />
                            {formik.errors.phone && formik.touched.phone ? (
                                <div className="error-warpper">{formik.errors.phone}</div>
                            ) : null}
                            {errors && errors.phone ? (
                                <div className="error-warpper">{errors.phone}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-12 col-lg-6 mb-1 mt-3">
                            <label>Adres</label>
                            <Autocomplete
                                state={address}
                                setState={setAddress}
                                options={[]}
                                multiple={false}
                                getOptionsMethod={getPlaces}
                                value={formik.values.address.city}
                                handleOnSelect={handleAddressSelect}
                                placeholder="Ulica nr.budynku, Miasto"
                                isRequired={true}
                                handleFocusInputs={() => handleFocusInputs()}
                            />
                            {!_.isEmpty(formik.values.address) && <address>
                                <strong>Wybrany adres</strong><br />
                                {`${formik.values.address.postCode}, ${formik.values.address.locality}`}<br />
                                {`ul. ${formik.values.address.street} ${formik.values.address.streetNumber}`}<br />
                                {`woj. ${formik.values.address.area_1 || "-"}, powiat ${formik.values.address.area_2 || "-"}, gmina ${formik.values.address.area_3 || "-"}`}<br />
                            </address>}
                            {_.isEmpty(formik.values.address) && <strong>Nie wybrano adresu</strong>}

                            {formik.errors.address && formik.touched.address ? (
                                <div>
                                    {formik.errors.address.postCode && <div className="error-warpper">{formik.errors.address.postCode}</div>}
                                    {formik.errors.address.country && <div className="error-warpper">{formik.errors.address.country}</div>}
                                    {formik.errors.address.locality && <div className="error-warpper">{formik.errors.address.locality}</div>}
                                    {formik.errors.address.street && <div className="error-warpper">{formik.errors.address.street}</div>}
                                    {formik.errors.address.streetNumber && <div className="error-warpper">{formik.errors.address.streetNumber}</div>}
                                    {formik.errors.address.area_1 && <div className="error-warpper">{formik.errors.address.area_1}</div>}
                                    {formik.errors.address.area_2 && <div className="error-warpper">{formik.errors.address.area_2}</div>}
                                    {formik.errors.address.area_3 && <div className="error-warpper">{formik.errors.address.area_3}</div>}
                                </div>
                            ) : null}
                            {errors && errors.address ? (
                                <div>
                                    <div className="error-warpper">{errors.address.postCode}</div>
                                    <div className="error-warpper">{errors.address.country}</div>
                                    <div className="error-warpper">{errors.address.locality}</div>
                                    <div className="error-warpper">{errors.address.street}</div>
                                    <div className="error-warpper">{errors.address.streetNumber}</div>
                                    <div className="error-warpper">{errors.address.area_1}</div>
                                    <div className="error-warpper">{errors.address.area_2}</div>
                                    <div className="error-warpper">{errors.address.area_3}</div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    {formik.values.type === "company" && <div className="row">
                        <div className="form-group col-md-12 col-lg-6 mb-5 mt-3">
                            <label>Przypisz myjnie</label>
                            <Autocomplete
                                state={washes}
                                setState={setWashes}
                                options={[]}
                                multiple={true}
                                getOptionsMethod={getWashes}
                                value={formik.values.washes}
                                handleOnSelect={handleWashSelect}
                                placeholder="Wyszukaj myjnie"
                                isRequired={true}
                                handleFocusInputs={() => handleFocusInputs()}
                            />
                            {renderSelectedWashes()}

                            {formik.errors.washes && <div className="error-warpper">{formik.errors.washes}</div>}
                            {errors && errors.washes ? (
                                <div className="error-warpper">{errors.washes}</div>
                            ) : null}
                        </div>
                    </div>}
                </div>
            </div>
        </form>
    </div >
}

export default WashComponent;