import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PaginationComponent from './pagination.component';
import AppLoader from './loader.component';
import SortComponent from './sort.component';
import FilterComponent from './filter.component';
import '../assets/css/list.css';

class ListComponent extends Component {
    render() {
        let { Card, isLoading, data, total, limit, paginationRoute, setPaginationRoute, route, sortOptions, filterOptions, title, hidePagination, setFilterBy } = this.props;

        return (<div className="list-wrapper d-flex flex-fill flex-column">
            {title && <h5 className="text-center mb-3">{title}</h5>}
            <div className="d-flex flex-direction-row flex-wrap">
                {sortOptions && <SortComponent sortOptions={sortOptions} route={route} />}
                {filterOptions && <FilterComponent filterOptions={filterOptions} setFilterBy={setFilterBy} />}
            </div>
            {!isLoading && !hidePagination && <PaginationComponent totalCount={total || 0} route={route} paginationRoute={parseInt(paginationRoute)} setPaginationRoute={setPaginationRoute} limit={limit} />}
            {isLoading && <AppLoader />}
            {!isLoading && total === 0 && <div className="no-element-wrapper d-flex flex-fill justify-content-center align-items-center"><span>Brak wyników</span></div>}
            {!isLoading && data.map((singleRecord, index) => {
                return <Card key={`list-card-index${index}`} index={index} record={singleRecord} />
            })}
            {!isLoading && !hidePagination && <PaginationComponent totalCount={total || 0} route={route} paginationRoute={parseInt(paginationRoute)} setPaginationRoute={setPaginationRoute} limit={limit} />}
        </div>)
    }
}

ListComponent.propTypes = {
    Card: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    total: PropTypes.number.isRequired,
    paginationRoute: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    setPaginationRoute: PropTypes.func.isRequired,
    route: PropTypes.string.isRequired
};

export default ListComponent;

