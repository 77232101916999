import {
  SET_LOGGED_USER,
  LOGOUT_USER,
  APP_IS_LOADING,
  SET_QUERY,
  CLEAR_QUERY,
  SHOW_TOAST,
  CLEAR_TOAST,
  SET_SORT_BY,
  CLEAR_SORT_BY
} from '../actions/app.action';

const initialState = {
  isAuth: false,
  searchQuery: '',
  searchFields: '',
  loggedUser: {},
  appIsLoading: false,
  sortBy: null,
  toast: {
    show: false,
    type: 'info',
    message: ''
  }
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOGGED_USER:
      return {
        ...state,
        isAuth: true,
        loggedUser: action.loggedUser
      };
    case APP_IS_LOADING:
      return {
        ...state,
        appIsLoading: action.appIsLoading
      };
    case SET_QUERY:
      return {
        ...state,
        searchQuery: action.query
      };
    case SET_SORT_BY:
      return {
        ...state,
        sortBy: action.sortyByOption
      };
    case CLEAR_SORT_BY:
      return {
        ...state,
        sortBy: null
      };
    case CLEAR_QUERY:
      return {
        ...state,
        searchQuery: ''
      };
    case LOGOUT_USER:
      return {
        ...state,
        loggedUser: {},
        isAuth: false
      };
    case SHOW_TOAST:
      return {
        ...state, toast: {
          show: true,
          type: action.toastType,
          message: action.message
        }
      };
    case CLEAR_TOAST:
      return {
        ...state, toast: initialState.toast
      };
    default:
      return state;
  }
}