import React from 'react';
import { connect } from 'react-redux'
import NotificationDetailsComponent from '../components/notificationDetails.component';
import { getNotification, clearNotification, changeIsActive} from '../actions/notifications.action';

function NotificationDetailsContainer(props) {
    return (
        <NotificationDetailsComponent {...props} />
    );
}

const mapStateToProps = (state) => {
    return {
        notification: state.NotificationReducer.notification,
        isLoading: state.NotificationReducer.isLoading,
        loggedUser: state.AppReducer.loggedUser
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getNotification: (id) => dispatch(getNotification(id)),
        clearNotification: () => dispatch(clearNotification()),
        changeIsActive: () => dispatch(changeIsActive()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationDetailsContainer);
