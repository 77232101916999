import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import AppLoader from '../../application/components/loader.component';
import ModalComponent from '../../application/components/modal.component';
import "../assets/css/profile.css";

function EmailChangeModal(props) {
    const { show, handleChangeEmail, handleToggleModal, email, isLoading } = props;
    const [errors, setApiErrors] = useState(null);
    const formik = useFormik({
        initialValues: {
            email: email,
            password: ''
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Wartość jest wymagana").email("Nieprawidłowy adres email"),
            password: Yup.string().required("Wartość jest wymagana"),
        }),
        onSubmit: (values) => {
            setApiErrors(null);
            handleChangeEmail(values).then(() => {
                formik.setSubmitting(false);
                handleToggleModal();
            }, (errors) => {
                setApiErrors(errors);
                formik.setSubmitting(false);
            });
        }
    });

    function renderModalContent() {
        return (<form onSubmit={formik.handleSubmit}>
            {isLoading && <AppLoader type="absolute" />}
            <div className="d-flex flex-fill">
                <div className="flex-fill">
                    <div className="row">
                        <div className="form-group col-12">
                            <label htmlFor="login">Email</label>
                            <input
                                autoComplete="new-password"
                                type="email"
                                className={`form-control ${(formik.errors && formik.touched.email && formik.errors.email) || (errors && errors.email) ? 'is-error' : ''}`}
                                onChange={formik.handleChange}
                                value={formik.values.email}
                                name="email"
                                id="email" />
                            {formik.errors.email && formik.touched.email ? (
                                <div className="error-warpper">{formik.errors.email}</div>
                            ) : null}
                            {errors && errors.email ? (
                                <div className="error-warpper">{errors.email}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-12">
                            <label htmlFor="password">Hasło</label>
                            <input
                                autoComplete="password"
                                type="password"
                                className={`form-control ${(formik.errors && formik.touched.password && formik.errors.password) || (errors && errors.password) ? 'is-error' : ''}`}
                                onChange={formik.handleChange}
                                value={formik.values.password}
                                name="password"
                                id="password" />
                            {formik.errors.password && formik.touched.password ? (
                                <div className="error-warpper">{formik.errors.password}</div>
                            ) : null}
                            {errors && errors.password ? (
                                <div className="error-warpper">{errors.password}</div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </form>);
    }

    return (<ModalComponent
        content={renderModalContent()}
        show={show}
        handleSubmit={formik.handleSubmit}
        handleToggleModal={handleToggleModal}
        contentText="Zmiana email"
        submitText="Zapisz" />);
}

export default EmailChangeModal;
