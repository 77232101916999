const dicts = {
    card_payment: "Płatność kartą",
    active_foam: "Aktywna piana",
    pressure_washing: "Mycie ciśnieniowe",
    waxing: "Woskowanie",
    rims_washing: "Mycie felg",
    chassis_washing: "Mycie podwozia",
    drying: "Osuszanie",
    invoice: "Możliwość uzyskania faktury",
    windows_inside_washing: "Mycie szyb od wewnątrz",
    interior_washing: "Pranie tapicerki",
    tire_polishing: "Nabłyszczanie opon",
    interior_plastics_polishing: "Nabłyszczanie plastików wewnętrznych",
    external_plastics_polishing: "Nabłyszczanie plastików zewnętrznych",
    sale_preparation: "Przygotowanie auta do sprzedaży",
    insect_removal: "Usuwanie insektów",
    tar_removal: "Usuwanie smoły",
    leather_regeneration: "Regeneracja i czyszczenie skór",
    hand_waxing: "Woskowanie ręczne",
    interior_vacuuming: "Odkurzanie wnętrza",
    brush: "Szczotka",
    paint_cosmetics: "Kosmetyka lakieru",
    hand_dryer: "Suszarka ręczna",
    vacuum: "Odkurzacz",
    rim_spraying: "Oprysk felg",
    insect_spraying: "Oprysk na owady",
    windscreen_washer: "Płyn do spryskiwaczy",
    bike_wash: "Możliwość mycia rowerów",
    machinery_wash: "Możliwość mycia maszyn budowlanych",
    money_changer: "Rozmieniarka pieniędzy",
    chips_purchase: "Możliwość zakupu żetonów",
    payment_key_purchase: "Możliwość zakupu klucza do płatności",
    mobile_app: "Aplikacja mobilna obsługująca myjnię",
    compressor: "Kompresor",
    steam_wash: "Pranie parowe",
    waiting_area: "Strefa oczekiwania"

}

export default dicts;