import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { useParams, Link, useHistory } from "react-router-dom";
import AppLoader from '../../application/components/loader.component';
import ModalComponent from '../../application/components/modal.component';
import Api from "../../application/services/api.service";
import StatusChangeModal from './statusChangeModal.component';
import '../assets/css/wash.css';
import WashPaymentCard from "./washPaymentCard.component";
import List from '../../application/components/list.component';
import featuresDicts from "../assets/featuresDicts";
import PhotoModal from '../../application/components/photoModal.component';
import CallcenterModal from "./callcenterModal.component";
import PaymentModal from "./paymentModal.component";
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const typeLabels = {
    "1": "automatyczna",
    "2": "bezdotykowa",
    "3": "ręczna"
}

const statusNames = {
    "1": "Oczekuje na akceptację",
    "2": "Odrzucona",
    "3": "Zaakceptowana"
}

const callcenterStatusNames = {
    "noanswer": "Nie odbiera",
    "interested": "Zainteresowany",
    "notinterested": "Niezainteresowany"
}

const containerStyle = {
    width: '100%',
    height: '300px'
}

function WashDetailsComponent(props) {

    let { id } = useParams();
    let history = useHistory();
    const { getWash, clearWash, wash, isLoading, changeIsActive, deleteWash, changeStatus, loggedUser, changeCallcenterStatus, paymentPackages, getPaymentPackages, clearPaymentPackages, saveWashPayment } = props;
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showStatusChangeModal, setShowStatusChangeModal] = useState(false);
    const [modalType, setModalType] = useState(1);
    const [showCallcenterModal, setShowCallcenterModal] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [showPhotoModal, setShowPhotoModal] = useState(false);
    const [photoForViewing, setPhotoForViewing] = useState(false);

    useEffect(() => {
        if (id) {
            getWash(id);
        }
        getPaymentPackages();
        return () => {
            clearWash();
            clearPaymentPackages();
        }
    }, [id, getWash, clearWash, getPaymentPackages, clearPaymentPackages]);

    function toggleShowPhotoModal() {
        setShowPhotoModal(!showPhotoModal);
    }

    function toggleConfirmationModal(type) {
        setModalType(type);
        setShowConfirmationModal(!showConfirmationModal);
    }

    function handleChangeIsActive() {
        toggleConfirmationModal(); // zamykamy modal
        changeIsActive();
    }

    function handleDeleteWash() {
        toggleConfirmationModal(); // zamykamy modal
        deleteWash(history);
    }

    function toggleStatusChangeModal() {
        setShowStatusChangeModal(!showStatusChangeModal);
    }

    function togglePaymentModal() {
        setShowPaymentModal(!showPaymentModal);
    }

    function toggleCallcenterModal() {
        setShowCallcenterModal(!showCallcenterModal);
    }

    function handlePhotoClick(photo) {
        setPhotoForViewing(photo);
        toggleShowPhotoModal();
    }

    function renderGallery() {
        const { wash } = props;
        if (!_.isEmpty(wash.gallery)) {
            let photos = wash.gallery.map((file, index) => {
                return <img className="mr-1 wash-gallery-photo mt-1" src={Api.getHost() + file} alt="Zdjęcie" key={index} onClick={() => handlePhotoClick(Api.getHost() + file)} />
            });
            return <div className="gallery">{photos}</div>
        }
        return null;
    }

    function renderPayments() {
        const { wash } = props;
        if (_.isArray(wash.payments)) {
            return <List
                data={wash.payments}
                Card={WashPaymentCard}
                isLoading={false}
                total={wash.payments.length}
                paginationRoute={1}
                limit={100}
                setPaginationRoute={() => null}
                route="/washes"
                title="Płatności"
                hidePagination={true}
            />
        }
        return null;
    }

    function getCoordinates(coordinatesString) {
        let coords = wash.coordinatesString.split(',');

        return {
            lat: parseFloat(coords[0]),
            lng: parseFloat(coords[1])
        }
    }

    return <div className="user-wrapper flex-column d-flex flex-fill">
        <PhotoModal
            show={showPhotoModal}
            handleToggleModal={() => toggleShowPhotoModal()}
            photo={photoForViewing}
        />
        {isLoading && <AppLoader type="absolute" />}
        {wash && <div>
            <div className="save-wrapper d-flex justify-content-between align-items-center">
                <h4>Szczegóły myjni</h4>
                <div className="d-flex col-md-5 flex-row flex-wrap justify-content-md-end p-0">
                    <div className="save-button d-flex flex-wrap">
                        {loggedUser.type === "admin" && <Link to={`/wash/${id}`} className="btn btn-dark mr-md-2 ">EDYTUJ</Link>}
                        {(loggedUser.type === "admin" || loggedUser.type === "callcenter") && <button type="button" className="btn btn-primary mr-md-2" onClick={() => toggleCallcenterModal()}>CALLCENTER</button>}
                        {/* {loggedUser.type === "admin" && <button type="button" className="btn btn-primary mr-2" onClick={() => toggleStatusChangeModal()}>ZMIEŃ STATUS</button>}
                    {loggedUser.type === "admin" && <button type="button" className="btn btn-primary" onClick={() => toggleConfirmationModal()}>{wash.active ? "DEAKTYWUJ" : "AKTYWUJ"}</button>} */}
                    </div>
                    <div className="dropdown col-sm-12 col-md-5 p-0"> 
                        <button className="btn btn-primary dropdown-toggle col-sm-12"
                            type="button" id="dropdownMenu1" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            ADMINISTRACJA
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenu1">
                            <button className="dropdown-item" onClick={() => togglePaymentModal()}>DODAJ PŁATNOŚĆ</button>
                            <button className="dropdown-item" onClick={() => toggleStatusChangeModal()}>ZMIEŃ STATUS</button>
                            <button className="dropdown-item" onClick={() => toggleConfirmationModal(1)}>{wash.active ? "DEAKTYWUJ" : "AKTYWUJ"}</button>
                            <button className="dropdown-item" onClick={() => toggleConfirmationModal(2)}>USUŃ</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="media">
                {wash.avatar && <img className="d-flex mr-3 wash-avatar" src={Api.getHost() + wash.avatar} alt="Logo myjni" />}
                <div className="media-body">
                    <div className="wash-detail">
                        <h5 className="mt-0"><b>{wash.name}</b></h5>
                        <p>{`Myjnia ${typeLabels[_.get(wash, "types[0]")]}`}</p>
                        {wash.hasOwnProperty("points") && <p className="text-success">Przyznane punkty: {wash.points}</p>}
                    </div>
                    <div className="wash-detail">
                        <label>Data utworzenia</label>
                        <p>{moment(wash.createdAt).format("DD.MM.YYYY HH:mm")}</p>
                    </div>
                    {wash.activeUntil && <div className="wash-detail">
                        <label>Opłacona do</label>
                        <p>{moment(wash.activeUntil).format("DD.MM.YYYY HH:mm")}</p>
                    </div>}
                    <div className="wash-detail">
                        <label>Dodana przez</label>
                        <p>{_.get(wash, "creator.name", "-")}</p>
                    </div>
                    <div className="wash-detail">
                        <label>Właściciel</label>
                        <p>{_.get(wash, "owner.name", "-")}</p>
                    </div>
                    <div className="wash-detail">
                        <label>Status</label>
                        <p>{statusNames[wash.status]}</p>
                    </div>
                    <div className="wash-detail">
                        <label>Status Callcenter</label>
                        <p>{callcenterStatusNames[wash.callcenterStatus] || "Brak"}</p>
                    </div>
                    <div className="wash-detail">
                        <label>Telefon</label>
                        <p>{wash.phone || "Brak"}</p>
                    </div>
                    <div className="wash-detail">
                        <label>Stanowiska</label>
                        <p>{wash.stands}</p>
                    </div>
                    <div className="wash-detail">
                        <label>Dostępne opcje</label>
                        <p>{wash.features && _.join(wash.features.map((feature) => featuresDicts[feature]), ", ")}</p>
                    </div>
                    {wash.address && <div className="pb-4">
                        <address>
                            <strong>Adres</strong><br />
                            {`${wash.address.postCode || '(brak kodu pocztowego)'}, ${wash.address.locality || '(brak miasta)'}`}<br />
                            {`ul. ${wash.address.street || '(brak ulicy)'} ${wash.address.streetNumber || '(brak numeru ulicy)'}`}<br />
                            {`woj. ${wash.address.area_1 || "(brak województwa)"}, powiat ${wash.address.area_2 || "(brak powiatu)"}, gmina ${wash.address.area_3 || "(brak gminy)"}`}<br />
                            {wash.location && <span>Koordynaty: {_.join(wash.location.coordinates, ", ")}</span>}
                        </address>
                        {wash.coordinatesString &&
                            <LoadScript
                                googleMapsApiKey="AIzaSyCBLOW9z74boeHGbet7Mdt6V9tTGsJLGqQ"
                            >
                                <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    center={getCoordinates(wash.coordinatesString)}
                                    zoom={15}
                                >
                                    <Marker
                                        position={getCoordinates(wash.coordinatesString)}
                                    />
                                </GoogleMap>
                            </LoadScript>
                        }
                    </div>
                    }
                </div>
            </div>
            {renderGallery()}
            {wash.payments && renderPayments()}
        </div>}
        <ModalComponent
            show={showConfirmationModal}
            handleSubmit={modalType === 1 ? handleChangeIsActive : handleDeleteWash}
            handleToggleModal={toggleConfirmationModal}
            contentText={`Czy na pewno chcesz ${modalType === 1 ? wash.active ? "deaktywować" : "aktywować" : "usunąć"} myjnię?`}
            submitText={modalType === 1 ? wash.active ? "Deaktywuj" : "Aktywuj" : "Usuń"} />
        {wash && wash.status && <StatusChangeModal show={showStatusChangeModal} handleChangeStatus={changeStatus} initialStatus={wash.status} handleToggleModal={toggleStatusChangeModal} isLoading={isLoading} washId={wash._id} />}
        {wash && <CallcenterModal show={showCallcenterModal} handleChangeStatus={changeCallcenterStatus} initialStatus={wash.callcenterStatus || "none"} handleToggleModal={toggleCallcenterModal} isLoading={isLoading} washId={wash._id} />}
        {wash && <PaymentModal show={showPaymentModal} handleSavePayment={saveWashPayment} handleToggleModal={togglePaymentModal} isLoading={isLoading} packages={paymentPackages} washId={id} />}
    </div>
}

export default WashDetailsComponent;

