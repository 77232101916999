import React from 'react';
import { connect } from 'react-redux'
import NotificationsComponent from '../components/notifications.component';

import { getNotifications, clearNotifications, setPaginationRoute, clearPaginationRoute } from '../actions/notifications.action';
import { clearQuery } from '../../application/actions/app.action';

function NotificationsContainer(props) {

    return (
        <NotificationsComponent {...props} />
    );
}

const mapStateToProps = (state) => {

    return {
        isLoading: state.NotificationReducer.isLoading,
        notifications: state.NotificationReducer.notifications,
        total: state.NotificationReducer.total,
        limit: state.NotificationReducer.limit,
        paginationRoute: state.NotificationReducer.page,
        sortOptions: state.NotificationReducer.sortOptions
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getNotifications: () => dispatch(getNotifications()),
        clearNotifications: () => dispatch(clearNotifications()),
        setPaginationRoute: (page) => dispatch(setPaginationRoute(page)),
        clearPaginationRoute: () => dispatch(clearPaginationRoute()),
        clearQuery: () => dispatch(clearQuery())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsContainer);