import React from 'react';
import LoginComponent from '../components/login.component';

import { connect } from 'react-redux'
import { loginIn } from '../actions/app.action';

function LoginContainer(props) {
    return (
        <LoginComponent {...props} />
    );
}


const mapStateToProps = (state) => ({
    isAuth: state.AppReducer.isAuth
});

const mapDispatchToProps = (dispatch) => {
    return {
        loginIn: (loginData) => loginIn(loginData, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
