import {
    SET_PROFILE,
    CLEAR_PROFILE,
    IS_LOADING
} from '../actions/profile.action';

const initialState = {
    isLoading: false,
    profile: {},
};

export default function profileReducer(state = initialState, action) {
    switch (action.type) {
        case SET_PROFILE:
            return {
                ...state,
                profile: action.profile
            };
        case CLEAR_PROFILE:
            return {
                ...state,
                profile: {}
            };
        case IS_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            };
        default:
            return state;
    }
}