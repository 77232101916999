import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import AppLoader from '../../application/components/loader.component';
import ModalComponent from '../../application/components/modal.component';
import _ from 'lodash';

function PaymentModal(props) {
    const { show, handleSavePayment, handleToggleModal, isLoading, packages, washId } = props;
    const [errors, setApiErrors] = useState(null);

    const formik = useFormik({
        initialValues: {
            package: ""
        },
        validationSchema: Yup.object({
            package: Yup.object().nullable().shape({
                _id: Yup.string(),
                periodType: Yup.string(),
                periodValue: Yup.number(),
                price: Yup.number(),
                desription: Yup.string(),
                name: Yup.string(),
                foreignId: Yup.string()
            })
        }),
        onSubmit: (values) => {
            if (values.package !== "") {
                setApiErrors(null);
                values.washId = washId;
                handleSavePayment(values).then(() => {
                    formik.setSubmitting(false);
                    handleToggleModal();
                }, (errors) => {
                    setApiErrors(errors);
                    formik.setSubmitting(false);
                });
            } else {
                setApiErrors({package: "Nie wybrano pakietu"});
            }
        }
    });

    function renderPackages() {
        if (packages && _.isArray(packages)) {
            let options = [<option key={`packnone`} value="">Wybierz</option>]
            packages.forEach((pack, index) => {
                options.push(<option value={pack._id} key={`package${index}`}>{pack.name}</option>);
            });
            return options;
        }
        return null;
    }
    function renderModalContent() {
        return (<form onSubmit={formik.handleSubmit}>
            {isLoading && <AppLoader type="absolute" />}
            <div className="d-flex flex-fill">
                <div className="flex-fill">
                    <div className="row">
                        <div className="form-group col-12">
                            <label htmlFor="status">Wybierz płatność</label>
                            <select
                                autoComplete="package"
                                className={`form-control ${(formik.errors && formik.touched.package && formik.errors.package) || (errors && errors.package) ? 'is-error' : ''}`}
                                onChange={formik.handleChange}
                                value={formik.values.package}
                                name="package"
                                id="package">
                                {renderPackages()}
                            </select>
                            {formik.errors.package && formik.touched.package ? (
                                <div className="error-warpper">{formik.errors.package}</div>
                            ) : null}
                            {errors && errors.package ? (
                                <div className="error-warpper">{errors.package}</div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </form>);
    }

    return (<ModalComponent
        content={renderModalContent()}
        show={show}
        handleSubmit={formik.handleSubmit}
        handleToggleModal={handleToggleModal}
        contentText="Dodawanie płatności"
        submitText="Zapisz" />);
}

export default PaymentModal;
