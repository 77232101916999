import moment from "moment";
import React, { useEffect, useState } from 'react';
import { useParams, Link } from "react-router-dom";
import AppLoader from '../../application/components/loader.component';
import ModalComponent from '../../application/components/modal.component';
import List from '../../application/components/list.component';
import WashListCard from "../../washes/components/washListCard.component";
import '../assets/css/user.css';

const typeLabels = {
    "freelancer": "Freelancer",
    "company": "Firma",
    "admin": "Administrator"
}

function UserDetailsComponent(props) {

    let { id } = useParams();
    const { getUser, clearUser, user, isLoading, changeIsActive, resetPassword } = props;
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [passwordError, setPasswordError] = useState(null);

    useEffect(() => {
        if (id) {
            getUser(id);
        }
        return () => {
            clearUser();
        }
    }, [id, getUser, clearUser,]);

    function togglePasswordModal() {
        setShowPasswordModal(!showPasswordModal);
    }

    function toggleConfirmationModal() {
        setShowConfirmationModal(!showConfirmationModal);
    }

    function handleChangeIsActive() {
        toggleConfirmationModal(); // zamykamy modal
        changeIsActive();
    }

    function handlePasswordReset() {
        resetPassword(newPassword, id).then((result) => {
            togglePasswordModal();
        }, (error) => {
            setPasswordError(error);
        });
    }

    function renderModalContent() {
        return <div className="form-group mb-5">
            <label>Nowe hasło</label>
            <input type="text" className="form-control" onChange={(e) => setNewPassword(e.target.value)} placeholder="Wpisz nowe hasło" />
            {passwordError ? (
                <div className="error-warpper">{passwordError}</div>
            ) : null}
        </div>
    }

    function renderWashes(washes) {
        return <div>
            <List
                data={washes}
                Card={WashListCard}
                isLoading={false}
                total={washes.length}
                paginationRoute={1}
                limit={100}
                setPaginationRoute={() => null}
                route="/washes"
                title="Myjnie użytkownika"
                hidePagination={true}
            />
        </div>
    }

    return <div className="user-wrapper flex-column d-flex flex-fill">
        {isLoading && <AppLoader type="absolute" />}
        {user && <div>
            <div className="save-wrapper d-flex justify-content-between align-items-center">
                <h4>Szczegóły użytkownika</h4>
                <div className="save-button d-flex flex-wrap">
                    <Link className="btn btn-dark mr-md-2" to={`/user/${id}`}>EDYTUJ</Link>
                    <button type="button" className="btn btn-primary mr-md-2" onClick={() => togglePasswordModal()}>RESET HASŁA</button>
                    <button type="button" className="btn btn-primary" onClick={() => toggleConfirmationModal()}>{user.active ? "DEAKTYWUJ" : "AKTYWUJ"}</button>
                </div>
            </div>
            <div className="user-detail">
                <label>Imię i nazwisko / Nazwa firmy</label>
                <p>{user.name}</p>
            </div>
            <div className="user-detail">
                <label>Data utworzenia</label>
                <p>{moment(user.createdAt).format("DD.MM.YYYY HH:mm")}</p>
            </div>
            <div className="user-detail">
                <label>Typ</label>
                <p>{typeLabels[user.type]}</p>
            </div>
            <div className="user-detail">
                <label>Status</label>
                <p>{user.active ? "Aktywny" : "Nieaktywny"}</p>
            </div>
            <div className="user-detail">
                <label>Email</label>
                <p>{user.email}</p>
            </div>
            {user.NIP && <div className="user-detail">
                <label>NIP</label>
                <p>{user.NIP}</p>
            </div>}
            {user.address && <address>
                <strong>Adres</strong><br />
                {`${user.address.postCode}, ${user.address.locality}`}<br />
                {`ul. ${user.address.street} ${user.address.streetNumber}`}<br />
                {`woj. ${user.address.area_3}, powiat ${user.address.area_2}, gmina ${user.address.area_1}`}<br />
            </address>}
            {user.washes && renderWashes(user.washes)}
        </div>}
        <ModalComponent
            show={showConfirmationModal}
            handleSubmit={handleChangeIsActive}
            handleToggleModal={toggleConfirmationModal}
            contentText={`Czy na pewno chcesz ${user.active ? "deaktywować" : "aktywować"} konto?`}
            submitText={user.active ? "Deaktywuj" : "Aktywuj"} />
        <ModalComponent
            content={renderModalContent()}
            show={showPasswordModal}
            handleSubmit={handlePasswordReset}
            handleToggleModal={togglePasswordModal}
            contentText="Reset hasła"
            submitText="Zmień" />
    </div>
}

export default UserDetailsComponent;

