import Api from '../../application/services/api.service';
import { showToast } from '../../application/actions/app.action';
import _ from "lodash";
const namespace = 'USERS';

export const SET_USERS = `SET_USERS_${namespace}`;
export const CLEAR_USERS = `CLEAR_USERS_${namespace}`;
export const SET_USER = `SET_USER_${namespace}`;
export const CLEAR_USER = `CLEAR_USER_${namespace}`;
export const IS_LOADING = `IS_LOADING_${namespace}`;
export const SET_PAGE = `SET_PAGE_${namespace}`;
export const CLEAR_PAGE = `CLEAR_PAGE_${namespace}`;
export const SET_OFFSET = `SET_OFFSET_${namespace}`;

export const setUsers = (users, total) => ({
    type: SET_USERS,
    users,
    total
});

export const setUser = (user) => ({
    type: SET_USER,
    user
});

export const clearUsers = () => ({
    type: CLEAR_USERS
});

export const clearUser = () => ({
    type: CLEAR_USER
});

export const isLoading = (isLoading) => ({
    type: IS_LOADING,
    isLoading
});

export function setOffset(offset) {
    return (dispatch) => {
        dispatch({
            type: SET_OFFSET,
            offset: offset
        });
    }
}

export function setPaginationRoute(page) {
    return (dispatch, getState) => {

        const { UserReducer: { limit } } = getState();

        dispatch(setOffset(limit * ((page || 1) - 1)));
        dispatch({
            type: SET_PAGE,
            page: page || 1
        });
        dispatch(getUsers());
        window.scrollTo(0, 0);
    }
}

export function clearPaginationRoute() {
    return dispatch => {
        dispatch(setOffset(0));
        dispatch({
            type: CLEAR_PAGE
        });
        window.scrollTo(0, 0);
    }
}

export function getUser(userId) {
    return (dispatch, getState) => {

        const { UserReducer: { voivodeships } } = getState();

        dispatch(isLoading(true));

        return Api.get(`admin/user/${userId}`, null).then((result) => {
            dispatch(isLoading(false));
            if (result.success) {

                if (result.document && result.document.voivodeship) {
                    result.document.voivodeship = voivodeships.filter((voivodeship) => {
                        return voivodeship.value === result.document.voivodeship;
                    })[0];
                }

                dispatch(setUser(result.document));
            } else if ('errors' in result) {
                dispatch(showToast('alert', 'Błąd podczas pobierania użytkownika'));
            }
        });
    };
}

export function getUsers() {
    return (dispatch, getState) => {

        const { UserReducer: { limit, offset, queryFields, sortOptions }, AppReducer: { searchQuery, sortBy } } = getState();

        let defaultParams = {
            "sort[active]": -1,
            "limit": limit,
            "offset": offset
        }

        let defaultSortOption = sortOptions.filter((option) => {
            return option.default;
        })[0];

        let params = Object.assign({}, defaultParams, sortBy ? sortBy.value : defaultSortOption.value);

        if (searchQuery && queryFields) {
            queryFields.split(",").forEach((field) => {
                params[`${field}[orin]`] = searchQuery;
            });
        }

        dispatch(isLoading(true));

        return Api.get('admin/user', params).then((result) => {
            dispatch(isLoading(false));
            if (result.success) {
                dispatch(setUsers(result.documents, result.total));
            } else if ('errors' in result) {
                dispatch(showToast('alert', 'Błąd podczas pobierania użytkowników'));
            }
        });
    };
}

export function saveUser(userData, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch(isLoading(true));
        let objData = Object.assign({}, userData);

        if (objData._id) { // edycja
            return Api.put('admin/user', objData, objData._id).then((result) => {
                dispatch(isLoading(false));
                if (result.success) {
                    dispatch(showToast('info', 'Użytkownik został zaktualizowany'));
                    return resolve();
                } else if ('errors' in result) {
                    return reject(result.errors);
                }
            });
        } else { // dodawanie
            return Api.post('admin/user', objData).then((result) => {
                dispatch(isLoading(false));
                if (result.success) {
                    dispatch(showToast('info', 'Użytkownik został dodany'));
                    return resolve();
                } else if ('errors' in result) {
                    dispatch(showToast('alert', 'Błąd podczas zapisywania użytkownika'));
                    return reject(result.errors);
                }
            });
        }
    });
}

export const changeIsActive = () => {
    return async (dispatch, getState) => {
        dispatch(isLoading(true));

        const { UserReducer: { user } } = getState();
        let res = await Api.put("admin/changeIsActive", { isActive: !user.active }, user._id);
        dispatch(isLoading(false));
        if (res && res.success) {
            dispatch(showToast('info', 'Zapisano zmiany'));
            dispatch(setUser(Object.assign({}, user, { isActive: !user.active })));
        } else {
            dispatch(showToast('alert', 'Wystąpił błąd'));
        }
    }
}

export function resetPassword(password, userId, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch(isLoading(true));
        return Api.put('admin/resetPassword', { password: password }, userId).then((result) => {
            dispatch(isLoading(false));
            if (result.success) {
                dispatch(showToast('info', 'Hasło zostało zmienione'));
                return resolve();
            } else if ('errors' in result) {
                return reject(_.get(result, "errors.password", "Wystąpił błąd"));
            }
        });
    });
}

export function searchNipData(nip, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch(isLoading(true));
        return Api.get(`application/getNipData/${nip}`).then((result) => {
            dispatch(isLoading(false));
            if (result.success) {
                return resolve(result.document);
            } else {
                return reject();
            }
        });
    });
}

export function getWashes(query) {
    return new Promise((resolve, reject) => {
        return Api.get('admin/wash', {
            "name[or]": query,
            "address.city[or]": query,
            "owner[exists]": false,
            limit: 10,
            select: "name,formattedAddress"
        }).then((result) => {
            if (result.success) {
                let options = result.documents.map((wash) => {
                    return { name: `${wash.name} (${_.get(wash, "formattedAddress", 'brak adresu')})`, value: wash._id }
                })
                return resolve(options);
            } else if ('errors' in result) {
                return reject(result.errors);
            }
        });
    });
}

