import React from 'react';
import { connect } from 'react-redux';
import UserComponent from '../components/user.component';
import { getPlaces, getPlaceDetails } from "../../washes/actions/washes.action";
import { getUser, clearUser, saveUser, searchNipData, getWashes } from '../actions/users.action';

function UserContainer(props) {
    return (
        <UserComponent {...props} />
    );
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.UserReducer.isLoading,
        user: state.UserReducer.user,
        loggedUser: state.AppReducer.loggedUser
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUser: (id) => dispatch(getUser(id)),
        clearUser: () => dispatch(clearUser()),
        saveUser: (data) => saveUser(data, dispatch),
        getPlaces: (query, token) => getPlaces(query, token),
        getPlaceDetails: (placeId) => getPlaceDetails(placeId, dispatch),
        searchNipData: (nip) => searchNipData(nip, dispatch),
        getWashes: (query) => getWashes(query)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserContainer);
