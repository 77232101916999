import React from 'react';
import moment from 'moment';
import { Link } from "react-router-dom";
import '../assets/css/washCard.css';

moment.locale("pl");

const statusLabels = {
    "new": "Nowa",
    "registered": "Rozpoczęta",
    "verified": "Zweryfikowana",
    "finished": "Zakończona",
    "error": "Nieudana"
};

function WashPaymentCardComponent({ index, record }) {
    return <div className="d-flex list-card-wrapper flex-row">
        <Link className="d-flex flex-fill" to={`/washes`}>
            <div className="card-section card-label-wrapper justify-content-center w-25 align-items-start d-flex flex-fill flex-column">
                <div className="card-label">Pakiet</div>
                <div className="card-label-value">{record.package.name}</div>
            </div>
            <div className="card-section card-label-wrapper  justify-content-center w-25 align-items-start d-flex flex-fill flex-column">
                <div className="card-label">Data</div>
                <div className="card-label-value">{moment(record.createdAt).format('DD.MM.YYYY HH:mm')}</div>
            </div>
            <div className="card-section card-label-wrapper  justify-content-center w-25 align-items-start d-flex flex-fill flex-column">
                <div className="card-label">Status</div>
                <div className="card-label-value">{statusLabels[record.status]}</div>
            </div>
        </Link>
    </div>
}

export default WashPaymentCardComponent;