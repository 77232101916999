import React from 'react';
import { connect } from 'react-redux'
import UserDetailsComponent from '../components/userDetails.component';

import { getUser, clearUser, changeIsActive, resetPassword } from '../actions/users.action';

function UserDetailsContainer(props) {
    return (
        <UserDetailsComponent {...props} />
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.UserReducer.user,
        isLoading: state.UserReducer.isLoading
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUser: (id) => dispatch(getUser(id)),
        clearUser: () => dispatch(clearUser()),
        changeIsActive: () => dispatch(changeIsActive()),
        resetPassword: (password, userId) => resetPassword(password, userId, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailsContainer);
