import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import AppLoader from '../../application/components/loader.component';
import ModalComponent from '../../application/components/modal.component';

function CallcenterModal(props) {
    const { show, handleChangeStatus, handleToggleModal, initialStatus, isLoading, washId } = props;
    const [errors, setApiErrors] = useState(null);
    const formik = useFormik({
        initialValues: {
            callcenterStatus: initialStatus
        },
        validationSchema: Yup.object({
            callcenterStatus: Yup.string().required("Wartość jest wymagana")
        }),
        onSubmit: (values) => {
            setApiErrors(null);
            values.washId = washId;
            handleChangeStatus(values).then(() => {
                formik.setSubmitting(false);
                handleToggleModal();
            }, (errors) => {
                setApiErrors(errors);
                formik.setSubmitting(false);
            });
        }
    });

    function renderModalContent() {
        return (<form onSubmit={formik.handleSubmit}>
            {isLoading && <AppLoader type="absolute" />}
            <div className="d-flex flex-fill">
                <div className="flex-fill">
                    <div className="row">
                        <div className="form-group col-12">
                            <label htmlFor="status">Status</label>
                            <select
                                autoComplete="callcenterStatus"
                                className={`form-control ${(formik.errors && formik.touched.email && formik.errors.email) || (errors && errors.email) ? 'is-error' : ''}`}
                                onChange={formik.handleChange}
                                value={formik.values.callcenterStatus}
                                name="callcenterStatus"
                                id="callcenterStatus">
                                <option value="none">Brak</option>
                                <option value="noanswer">Nie odbiera</option>
                                <option value="interested">Zainteresowany</option>
                                <option value="notinterested">Niezainteresowany</option>
                            </select>
                            {formik.errors.callcenterStatus && formik.touched.callcenterStatus ? (
                                <div className="error-warpper">{formik.errors.callcenterStatus}</div>
                            ) : null}
                            {errors && errors.callcenterStatus ? (
                                <div className="error-warpper">{errors.callcenterStatus}</div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </form>);
    }

    return (<ModalComponent
        content={renderModalContent()}
        show={show}
        handleSubmit={formik.handleSubmit}
        handleToggleModal={handleToggleModal}
        contentText="Zmiana statusu callcenter"
        submitText="Zapisz" />);
}

export default CallcenterModal;
