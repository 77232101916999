import { combineReducers } from 'redux';

import AppReducer from './app.reducer';
import UserReducer from '../../users/reducers/user.reducer';
import WashReducer from '../../washes/reducers/wash.reducer';
import ProfileReducer from "../../profile/reducers/profile.reducer";
import NotificationReducer from "../../notifications/reducers/notification.reducer";

//LISTA REDUCERÓW
export default combineReducers({
  AppReducer,
  UserReducer,
  WashReducer,
  ProfileReducer,
  NotificationReducer
});