import {
    SET_WASHES,
    SET_WASH,
    CLEAR_WASHES,
    CLEAR_WASH,
    IS_LOADING,
    SET_PAGE,
    SET_OFFSET,
    SET_FILTER_BY,
    CLEAR_PAGE,
    SET_PAYMENT_PACKAGES,
    CLEAR_PAYMENT_PACKAGES
} from '../actions/washes.action';

const initialState = {
    isLoading: false,
    washes: [],
    wash: {},
    queryFields: 'name,address.locality,address.street,address.area_1,address.area_2,address.postCode',
    total: 0,
    page: 1,
    limit: 20,
    offset: 0,
    sortOptions: [
        { value: { 'sort[createdAt]': -1 }, label: 'Data utworzenia malejąco', default: true },
        { value: { 'sort[createdAt]': 1 }, label: 'Data utworzenia rosnąco' },
        { value: { 'sort[name]': -1 }, label: 'Nazwa malejąco' },
        { value: { 'sort[name]': 1 }, label: 'Nazwa rosnąco' }
    ],
    filterOptions: [
        { value: { 'active': true }, label: 'Aktywne', checked: false },
        { value: { 'activeUntil[gte]': Date.now() / 1000 }, label: 'Opłacone', checked: false }
    ],
    paymentPackages: []
};

export default function WashReducer(state = initialState, action) {
    switch (action.type) {
        case SET_WASHES:
            return {
                ...state,
                washes: action.washes,
                total: action.total
            };
        case SET_WASH:
            return {
                ...state,
                wash: action.wash,
            };
        case CLEAR_WASHES:
            return {
                ...state,
                washes: []
            };
        case CLEAR_WASH:
            return {
                ...state,
                wash: {}
            };
        case SET_FILTER_BY:
            return {
                ...state,
                filterOptions: action.newFilterOptions
            };
        case CLEAR_WASH:
            return {
                ...state,
                wash: {}
            };
        case SET_PAGE:
            return {
                ...state,
                page: action.page
            };
        case SET_OFFSET:
            return {
                ...state,
                offset: action.offset
            };
        case CLEAR_PAGE:
            return { ...state, page: 1 };

        case IS_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            };
        case SET_PAYMENT_PACKAGES:
            return {
                ...state,
                paymentPackages: action.packages
            };
        case CLEAR_PAYMENT_PACKAGES:
            return {
                ...state,
                paymentPackages: []
            };
        default:
            return state;
    }
}