import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

function FilterComponent(props) {

    const { filterOptions, setFilterBy } = props;

    function getFliterOptions() {
        return filterOptions.map((option, index) => {
            return <div key={`filter-key${index}`} className="form-group custom-control custom-checkbox mt-1 mb-0 mr-3">
                <input
                    type="checkbox"
                    className="custom-control-input"
                    onChange={(value) => {
                        setFilterBy(index, !option.checked);
                    }}
                    value={option.checked}
                    checked={option.checked}
                    name={option.label}
                    id={option.label}
                />
                <label className="custom-control-label" htmlFor={option.label}>{option.label}</label>
            </div>
        });
    }

    return (
        <div className="filter-wrapper d-flex mr-md-4">
            {getFliterOptions()}
        </div>
    )
}

FilterComponent.propTypes = {
    filterOptions: PropTypes.array.isRequired,
    setFilterBy: PropTypes.func.isRequired,
};

export default FilterComponent;


