import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import AppLoader from '../../application/components/loader.component';
import EmailChangeModal from './emailChangeModal.component';
import "../assets/css/profile.css";

function ProfileComponent(props) {

    const { getProfile, clearProfile, profile, isLoading, saveChanges, changeEmail } = props;

    const [showEmailChangeModal, setShowEmailChangeModal] = useState(false);

    useEffect(() => {
        getProfile();
        return () => {
            clearProfile();
        }
    }, [getProfile, clearProfile]);

    function toggleEmailChangeModal() {
        setShowEmailChangeModal(!showEmailChangeModal);
    }

    const formData = useCallback(setFormData, [profile]);
    useEffect(() => { // jak przyjdzie użytkownk w propsach to ustawiamy go na formularzu
        formData();
    }, [profile, formData]);

    function setFormData() {
        Object.keys(profile).map((name) => {
            formik.setFieldValue(name, profile[name]);
            return true;
        });
    }

    const [errors, setApiErrors] = useState(null);
    const formik = useFormik({
        initialValues: {
            name: '',
            newPassword: '',
            oldPassword: ''
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Wartość jest wymagana"),
            newPassword: Yup.string(),
            oldPassword: Yup.string()
        }),
        onSubmit: (values) => {
            setApiErrors(null);
            saveChanges(values).then(() => {
                formik.setSubmitting(false);
            }, (errors) => {
                setApiErrors(errors);
                formik.setSubmitting(false);
            });
        }
    });

    return <div className="user-wrapper flex-column d-flex flex-fill">
        {isLoading && <AppLoader type="absolute" />}
        <form onSubmit={formik.handleSubmit}>
            <div className="save-wrapper d-flex justify-content-between align-items-center">
                <h4>Edycja profilu</h4>
                <div className="save-button ">
                    <button type="button" className="btn btn-primary mr-2" onClick={() => toggleEmailChangeModal()}>ZMIEŃ EMAIL</button>
                    <button type="submit" disabled={formik.isSubmitting} className="btn btn-primary">ZAPISZ</button>
                </div>
            </div>
            <div className="d-flex flex-fill">
                <div className="flex-fill">
                    <div className="row">
                        <div className="form-group col-md-12 col-lg-6">
                            <label htmlFor="login">Imię i nazwisko / Nazwa firmy</label>
                            <input
                                autoComplete="new-password"
                                type="text"
                                className={`form-control ${(formik.errors && formik.touched.name && formik.errors.name) || (errors && errors.name) ? 'is-error' : ''}`}
                                onChange={formik.handleChange}
                                value={formik.values.name}
                                name="name"
                                id="name" />
                            {formik.errors.name && formik.touched.name ? (
                                <div className="error-warpper">{formik.errors.name}</div>
                            ) : null}
                            {errors && errors.name ? (
                                <div className="error-warpper">{errors.name}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-12 col-lg-6">
                            <label htmlFor="oldPassword">Stare hasło</label>
                            <input
                                autoComplete="old-password"
                                type="password"
                                className={`form-control ${(formik.errors && formik.touched.oldPassword && formik.errors.oldPassword) || (errors && errors.oldPassword) ? 'is-error' : ''}`}
                                onChange={formik.handleChange}
                                value={formik.values.oldPassword}
                                name="oldPassword"
                                id="oldPassword" />
                            {formik.errors.oldPassword && formik.touched.oldPassword ? (
                                <div className="error-warpper">{formik.errors.oldPassword}</div>
                            ) : null}
                            {errors && errors.oldPassword ? (
                                <div className="error-warpper">{errors.oldPassword}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-12 col-lg-6">
                            <label htmlFor="newPassword">Nowe hasło</label>
                            <input
                                autoComplete="new-password"
                                type="password"
                                className={`form-control ${(formik.errors && formik.touched.newPassword && formik.errors.newPassword) || (errors && errors.newPassword) ? 'is-error' : ''}`}
                                onChange={formik.handleChange}
                                value={formik.values.newPassword}
                                name="newPassword"
                                id="newPassword" />
                            {formik.errors.newPassword && formik.touched.newPassword ? (
                                <div className="error-warpper">{formik.errors.newPassword}</div>
                            ) : null}
                            {errors && errors.newPassword ? (
                                <div className="error-warpper">{errors.newPassword}</div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </form>
        {profile && profile.email && <EmailChangeModal show={showEmailChangeModal} handleChangeEmail={changeEmail} email={profile.email} handleToggleModal={toggleEmailChangeModal} isLoading={isLoading}/>}
    </div>
}

export default ProfileComponent;