import Api from '../../application/services/api.service';
import { showToast } from '../../application/actions/app.action';
const namespace = 'PROFILE';

export const SET_PROFILE = `SET_PROFILE_${namespace}`;
export const CLEAR_PROFILE = `CLEAR_PROFILE_${namespace}`;
export const IS_LOADING = `IS_LOADING_${namespace}`;

export const setProfile = (profile) => ({
    type: SET_PROFILE,
    profile
});

export const clearProfile = () => ({
    type: CLEAR_PROFILE
});

export const isLoading = (isLoading) => ({
    type: IS_LOADING,
    isLoading
});

export function getProfile() {
    return dispatch => {
        dispatch(isLoading(true));
        return Api.get(`profile/profile`, null).then((result) => {
            dispatch(isLoading(false));
            if (result.success) {
                dispatch(setProfile(result.document));
            } else {
                dispatch(showToast('alert', 'Wystąpił błąd'));
            }
        });
    };
}

export function saveChanges(data, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch(isLoading(true));
        return Api.put('profile/profile', data).then((result) => {
            dispatch(isLoading(false));
            if (result.success) {
                dispatch(showToast('info', 'Profil został zaktualizowany'));
                return resolve();
            } else if ('errors' in result) {
                return reject(result.errors);
            }
        });
    });
}

export function changeEmail(data, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch(isLoading(true));
        return Api.put('profile/changeEmail', data).then((result) => {
            dispatch(isLoading(false));
            if (result.success) {
                dispatch(showToast('info', 'Email został zaktualizowany'));
                return resolve();
            } else if ('errors' in result) {
                return reject(result.errors);
            }
        });
    });
}