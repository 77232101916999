import React from 'react';
import { connect } from 'react-redux'
import WashDetailsComponent from '../components/washDetails.component';
import { getWash, clearWash, changeIsActive, deleteWash, changeStatus, changeCallcenterStatus, getPaymentPackages, clearPaymentPackages, saveWashPayment } from '../actions/washes.action';

function WashDetailsContainer(props) {
    return (
        <WashDetailsComponent {...props} />
    );
}

const mapStateToProps = (state) => {
    return {
        wash: state.WashReducer.wash,
        isLoading: state.WashReducer.isLoading,
        loggedUser: state.AppReducer.loggedUser,
        paymentPackages: state.WashReducer.paymentPackages
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getWash: (id) => dispatch(getWash(id)),
        clearWash: () => dispatch(clearWash()),
        changeIsActive: () => dispatch(changeIsActive()),
        changeStatus: (data) => changeStatus(data, dispatch),
        changeCallcenterStatus: (data) => changeCallcenterStatus(data, dispatch),
        getPaymentPackages: () => dispatch(getPaymentPackages()),
        clearPaymentPackages: () => dispatch(clearPaymentPackages()),
        saveWashPayment: (data) => saveWashPayment(data, dispatch),
        deleteWash: (history) => dispatch(deleteWash(history))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(WashDetailsContainer);
