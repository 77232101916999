import React from 'react';
import { Modal } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

function PhotoModal(props) {

    const { show, handleToggleModal, photo } = props;

    return (
        <Modal show={show} centered onHide={handleToggleModal}>
            <div className="modal-content">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <span id="modalClose" className="cursor-pointer modal-close" data-dismiss="modal" aria-label="Close">
                            <FontAwesomeIcon icon={faTimes} color="#44503f" onClick={handleToggleModal} />
                        </span>
                    </div>
                    <div>
                        <img src={photo} alt="Pełny rozmiar zdjęcia" />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default PhotoModal;