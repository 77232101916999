import React from 'react';
import { connect } from 'react-redux'
import ProfileComponent from '../components/profile.component';

import { getProfile, clearProfile, saveChanges, changeEmail } from '../actions/profile.action';

function ProfileContainer(props) {

    return (
        <ProfileComponent {...props} />
    );
}

const mapStateToProps = (state) => {
    return {
        profile: state.ProfileReducer.profile,
        isLoading: state.ProfileReducer.isLoading
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProfile: () => dispatch(getProfile()),
        clearProfile: () => dispatch(clearProfile()),
        saveChanges: (data) => saveChanges(data, dispatch),
        changeEmail: (data) => changeEmail(data, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);
